<template>
  <v-main>
    <router-view />

    <dashboard-core-footer />
    <alert />
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
    Alert: () => import("../base/Alert"),
  },
};
</script>
